import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SvgComponent from '../../assets/svg/SvgComponent';
import styles from './Quote.module.scss';

const Quote = ({ svgName, title = [], className, ...rest }) => {
    return (
        <div className={cx(styles.container, className)} {...rest}>
            {svgName && <SvgComponent name={svgName} className={styles.svg} />}
            <span className={styles.title}>{title.join('\n')}</span>
        </div>
    );
};

Quote.propTypes = {
    svgName: PropTypes.string,
    title: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
};

export default Quote;
