import React from 'react';
import PropTypes from 'prop-types';
import styles from './AccordionContent.module.scss';

class AccordionContent extends React.Component {
    ref = React.createRef();

    componentDidUpdate(prevProps) {
        if (prevProps.isVisible !== this.props.isVisible) {
            this.ref.current.style.maxHeight = this.props.isVisible ? `${this.ref.current.scrollHeight}px` : '0px';
        }
    }

    render() {
        return (
            <div ref={this.ref} className={`${styles.contentContainer} ${this.props.isVisible ? styles.active : ''}`}>
                <p className={styles.content}>{this.props.children}</p>
            </div>
        );
    }
}

AccordionContent.propTypes = {
    isVisible: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

AccordionContent.defaultProps = {
    isVisible: false,
};

export default AccordionContent;
