import React from 'react';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/Seo';
import Theme from '../components/Theme/Theme';
import SellingPageGit from '../components/SellingPage/Git/SellingPageGit';

const IndexPage = () => (
    <Layout>
        <SEO title="Home" />
        <Theme accentKey="git">
            <SellingPageGit />
        </Theme>
    </Layout>
);

export default IndexPage;
