import React from 'react';
import PropTypes from 'prop-types';
import SvgComponent from '../../../assets/svg/SvgComponent';
import styles from './AccordionHeader.module.scss';

const AccordionHeader = ({ title, onClick, isActive }) => {
    return (
        <div
            role="button"
            onClick={onClick}
            onKeyDown={onClick}
            tabIndex="0"
            className={`${styles.header} ${isActive ? styles.active : ''}`}
        >
            {title}
            <SvgComponent name="plus" className={styles.svg} />
        </div>
    );
};

AccordionHeader.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
};
AccordionHeader.defaultProps = {
    title: '',
    isActive: false,
    onClick: () => {},
};

export default AccordionHeader;
