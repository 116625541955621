import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './CodeWindow.module.scss';

const CodeWindow = ({ src, alt, isShadowLeft }) => {
    return (
        <div
            className={cx(
                styles.container,
                isShadowLeft && styles.isShadowLeft
            )}
        >
            <div className={styles.topBar}>
                <div className={styles.leftButton}></div>
                <div className={styles.centerButton}></div>
                <div className={styles.rightButton}></div>
            </div>
            <img alt={alt} src={src} className={styles.codeImage}></img>
        </div>
    );
};

CodeWindow.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    isShadowLeft: PropTypes.bool,
};

export default CodeWindow;
