import React from 'react';
// import { useLocation } from '@reach/router';
import cx from 'classnames';
import PropTypes from 'prop-types';
import SvgComponent from '../../assets/svg/SvgComponent';
// import Button from '../Button/Button';
import styles from './OfferCards.module.scss';
// import { loadStripe } from '@stripe/stripe-js';

// const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_API_KEY);

/*
const redirectCheckout = async (offerPrice, cancelUrl) => {
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
        lineItems: [{ price: offerPrice, quantity: 1 }],
        mode: 'payment',
        // Do not rely on the redirect to the successUrl for fulfilling
        // purchases, customers may not always reach the success_url after
        // a successful payment.
        // Instead use one of the strategies described in
        // https://stripe.com/docs/payments/checkout/fulfillment
        successUrl: process.env.GATSBY_STRIPE_SUCCESS_URL,
        cancelUrl,
    });

    if (error) {
        console.warn('Error: ', error);
    }
};
*/

const recomendedOfferIndex = 1;
const offersContent = [
    'Accès à tous les tests de cette formation',
    "Accessible de n'importe quel appareil",
    'Mises à jour illimitées',
];

const OfferCards = ({ offers, className }) => {
    // const location = useLocation();

    const formatDate = date => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return `${day}/${month}/${year}`;
    };

    /*
    const getButtonTheme = displayColor => {
        let buttonTheme;
        switch (displayColor) {
            case 'gitOrange':
                buttonTheme = Button.Theme.ORANGE;
                break;
            case 'gitBlue':
                buttonTheme = Button.Theme.BLUE;
                break;
            default:
                buttonTheme = Button.Theme.BLUE;
        }
        return buttonTheme;
    };
*/
    // const handleBuyButtonClick = () => {
    //     // redirectCheckout(offer.stripePrice, location.href);
    // };

    return (
        <div className={cx(styles.offers, className)}>
            {offers &&
                offers.map((offer, index) => {
                    return (
                        <div
                            key={index}
                            className={cx(
                                styles.offerCard,
                                index === recomendedOfferIndex &&
                                    styles.recommendedOffer
                            )}
                        >
                            <SvgComponent
                                name={offer.svg}
                                className={cx(
                                    styles.offerSvg,
                                    index === recomendedOfferIndex &&
                                        styles.recommendedOfferSvg
                                )}
                            />
                            <div className={styles.offerContent}>
                                <h3 className={styles.offerTitle}>
                                    GIT
                                    <br />
                                    {offer.title}
                                </h3>
                                {'discountPrice' in offer ? (
                                    <>
                                        <div>
                                            <span className={styles.price}>
                                                {(offer.price / 100).toFixed(2)}{' '}
                                                €
                                            </span>
                                            <span
                                                className={styles.discountPrice}
                                            >
                                                {(
                                                    offer.discountPrice / 100
                                                ).toFixed(2)}{' '}
                                                €
                                            </span>
                                        </div>
                                        <span className={styles.save}>
                                            Save{' '}
                                            {Math.round(
                                                (1 -
                                                    offer.discountPrice /
                                                        offer.price) *
                                                    100
                                            )}{' '}
                                            %
                                        </span>
                                        <span className={styles.endDate}>
                                            Offre valable jusqu'au{' '}
                                            {formatDate(offer.endDate)}
                                        </span>
                                    </>
                                ) : (
                                    <div>
                                        <span className={styles.discountPrice}>
                                            {(offer.price / 100).toFixed(2)} €
                                        </span>
                                    </div>
                                )}

                                <ul className={styles.offerCardList}>
                                    <li
                                        key={index}
                                        className={styles.offerCardListItem}
                                    >
                                        <SvgComponent
                                            name="check"
                                            className={cx(
                                                offer.displayColor,
                                                styles.offerListItemIcon
                                            )}
                                        />
                                        <p style={{ margin: 0 }}>
                                            Les{' '}
                                            <strong
                                                className={cx(
                                                    styles.offerListItemStrong,
                                                    offer.displayColor ===
                                                        'gitBlue' &&
                                                        styles.textBlue
                                                )}
                                            >
                                                {offer.nbCapsules}
                                            </strong>{' '}
                                            capsules de formation sur Git
                                            marquées d'une pastille orange.
                                        </p>
                                    </li>
                                    {offersContent.map((item, index) => (
                                        <li
                                            key={index}
                                            className={styles.offerCardListItem}
                                        >
                                            <SvgComponent
                                                name="check"
                                                className={cx(
                                                    offer.displayColor,
                                                    styles.offerListItemIcon
                                                )}
                                            />
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                                <p>Bientôt disponible</p>
                                {/*
                                <Button.Component
                                    theme={getButtonTheme(offer.displayColor)}
                                    onClick={handleBuyButtonClick}
                                >
                                    Bientôt disponible
                                </Button.Component>
                                */}
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

OfferCards.propTypes = {
    offers: PropTypes.arrayOf(
        PropTypes.shape({
            svgName: PropTypes.string,
            title: PropTypes.string,
            price: PropTypes.number,
            discountPrice: PropTypes.number,
            content: PropTypes.arrayOf(
                PropTypes.oneOf([PropTypes.string, PropTypes.node])
            ),
        })
    ),
};

export default OfferCards;
