import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './CapsulesList.module.scss';
import SvgComponent from '../../assets/svg/SvgComponent';

const CapsulesList = ({ topics = [], offers: allOffers }) => {
    let capsuleIndex = 0;
    return (
        <React.Fragment>
            {topics.map(({ _id, title, description, slug, lessons }) => {
                return (
                    <div key={_id} className={styles.container}>
                        <h3 className={styles.title}>{title}</h3>
                        <span className={styles.nbCapsules}>
                            ({lessons.length} capsules)
                        </span>
                        <p className={styles.description}>{description}</p>

                        <div>
                            {lessons &&
                                lessons.map(({ title, stripeProducts }) => {
                                    ++capsuleIndex;
                                    return (
                                        <div
                                            key={capsuleIndex}
                                            className={styles.cell}
                                        >
                                            <span className={styles.cellNumber}>
                                                {capsuleIndex}
                                            </span>

                                            <div
                                                className={
                                                    styles.cellDescContainer
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles.cellDescription
                                                    }
                                                >
                                                    {title}
                                                </p>
                                                <div
                                                    className={
                                                        styles.indicatorContainer
                                                    }
                                                >
                                                    {stripeProducts.some(
                                                        id =>
                                                            id ===
                                                            allOffers[0]
                                                                .stripeProduct
                                                    ) && (
                                                        <SvgComponent
                                                            name="check"
                                                            className={cx(
                                                                allOffers[0]
                                                                    .displayColor,
                                                                styles.cellIndicator
                                                            )}
                                                        />
                                                    )}
                                                    {stripeProducts.some(
                                                        id =>
                                                            id ===
                                                            allOffers[1]
                                                                .stripeProduct
                                                    ) && (
                                                        <SvgComponent
                                                            name="check"
                                                            className={cx(
                                                                allOffers[1]
                                                                    .displayColor,
                                                                styles.cellIndicator
                                                            )}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                );
            })}
        </React.Fragment>
    );
};

CapsulesList.propTypes = {
    topics: PropTypes.array,
};

export default CapsulesList;
