import React from 'react';
import cx from 'classnames';
import Container from '../../Container/Container';
import FeatureRow from '../../FeatureRow/FeatureRow';
import FeatureCenter from '../../FeatureCenter/FeatureCenter';
import SvgComponent from '../../../assets/svg/SvgComponent';
import Quote from '../../Quote/Quote';
import Accordion from '../../Accordion/Accordion';
import CodeWindow from '../../CodeWindow/CodeWindow';
import OfferCards from '../../OfferCards/OfferCards';
import CheckMiniCard from '../../CheckMiniCard/CheckMiniCard';
import CapsulesList from '../../CapsulesList/CapsulesList';
import CustomVideoPlayer from '../../CustomVideoPlayer/CustomVideoPlayer';

import apiOffersGit from '../../../content/apiOffersGit.json';
import apiCapsulesGit from '../../../content/apiCapsulesGit.json';
import styles from './SellingPageGit.module.scss';

const GitSellingPage = () => {
    return (
        <div className="git">
            <div className={styles.hero}>
                <Container>
                    <div className={styles.gitLogoContainer}>
                        <SvgComponent
                            name="gitMonogramWhite"
                            className={styles.gitLogo}
                        ></SvgComponent>
                    </div>
                    <h1 className={styles.tagline}>
                        Nos capsules de formation
                        <br />
                        sur Git
                    </h1>
                    <p className={styles.heroSubtitle}>
                        <span role="img" aria-label="drapeau français">
                            🇫🇷
                        </span>{' '}
                        Exclusivité française{' '}
                        <span role="img" aria-label="drapeau français">
                            🇫🇷
                        </span>
                    </p>
                    <OfferCards
                        className={styles.offerCards}
                        offers={apiOffersGit}
                    />
                </Container>
            </div>
            <section className={styles.section}>
                <Container className={styles.alignCenter}>
                    <h2 className={styles.sectionTitle}>
                        Une formation
                        <br />
                        <strong>pas comme les autres</strong>
                    </h2>

                    <CustomVideoPlayer
                        vimeoVideoId="480236894"
                        title="Présentation de la formation git"
                    />
                </Container>
            </section>
            <section className={styles.section}>
                <h2 className={styles.sectionTitle}>
                    <strong>Pourquoi</strong> apprendre git ?
                </h2>
                <Container noTopBottomPadding>
                    <FeatureRow.Component
                        imagePosition={FeatureRow.ImagePosition.IMAGE_RIGHT}
                        svgName="chooseYourPath"
                        title={['Git est', 'incontournable']}
                        illustrationJSX={
                            <div className={styles.demoContainer}>
                                <CodeWindow
                                    alt="remiser les nouveaux fichiers"
                                    src="https://getknow.s3.eu-west-3.amazonaws.com/gif/git-init.gif"
                                />
                            </div>
                        }
                    >
                        <p>
                            Que tu travailles seul ou à plusieurs, en
                            télétravail ou pour différents clients, avant même
                            d'écrire la moindre ligne de code, le rituel est
                            généralement le même: récupérer / créer un dépôt
                            git.
                        </p>
                    </FeatureRow.Component>
                </Container>
            </section>
            <section className={cx(styles.section, styles.sectionWhite)}>
                <Container noTopBottomPadding>
                    <FeatureRow.Component
                        imagePosition={FeatureRow.ImagePosition.IMAGE_LEFT}
                        svgName="allTechnos"
                        title={['Quelque soit ton', 'langage']}
                    >
                        <p>
                            Point commun entre tous les projets, git est bien
                            souvent le{' '}
                            <span className={styles.underline}>
                                point de départ,
                            </span>{' '}
                            et ce quel que soit ton langage de prédilection:
                            javascript, python, php, swift, java, C++, iOS,
                            ruby, nodejs, HTML, CSS...
                        </p>
                    </FeatureRow.Component>
                </Container>
            </section>
            <section className={styles.section}>
                <Container noTopBottomPadding>
                    <FeatureCenter
                        title={[
                            'Git est largement utilisé',
                            'par les développeurs',
                            'en 2020',
                        ]}
                    >
                        <p>
                            Véritable chouchou des développeurs pour gérer le
                            code source de ses projets préférés, Git est devenu{' '}
                            <span className={styles.underline}>
                                incontournable
                            </span>
                            . D'ailleurs les plateformes d'hébergement de
                            référentiels git tels que Github, Gitlab et
                            Bitbucket ont littéralement explosé. Sais-tu que
                            Github représente à lui seul déjà plus de{' '}
                            <a
                                href="https://github.com/about"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.linkDisabled}
                            >
                                <span className={styles.underline}>
                                    50 millions d'utilisateurs
                                </span>
                            </a>{' '}
                            en 2020 toutes technologies confondues !
                        </p>
                        <div className={styles.cardContainer}>
                            <div className={styles.card}>
                                <div>
                                    <SvgComponent
                                        name="githubMonogram"
                                        className={styles.cardSvg}
                                    />
                                    <span className={styles.platformName}>
                                        Github
                                    </span>
                                </div>
                                <div>
                                    <span className={styles.platformFigure}>
                                        100 M
                                    </span>
                                    <span className={styles.platformFigureUnit}>
                                        de dépôts
                                    </span>
                                </div>
                            </div>
                            <div className={styles.card}>
                                <div>
                                    <SvgComponent
                                        name="bitbucketMonogram"
                                        className={styles.cardSvg}
                                    />
                                    <span className={styles.platformName}>
                                        Bitbucket
                                    </span>
                                </div>
                                <div>
                                    <span className={styles.platformFigure}>
                                        28 M
                                    </span>
                                    <span className={styles.platformFigureUnit}>
                                        de dépôts
                                    </span>
                                </div>
                            </div>
                            <div className={styles.card}>
                                <div>
                                    <SvgComponent
                                        name="gitlabMonogram"
                                        className={styles.cardSvg}
                                    />
                                    <span className={styles.platformName}>
                                        Gitlab
                                    </span>
                                </div>
                                <div>
                                    <span className={styles.platformFigure}>
                                        100 000
                                    </span>
                                    <span className={styles.platformFigureUnit}>
                                        entreprises
                                    </span>
                                </div>
                            </div>
                        </div>
                    </FeatureCenter>
                </Container>
            </section>
            <section className={cx(styles.section, styles.centerQuote)}>
                <Container>
                    <Quote
                        title={[
                            `Utiliser Git aujourd'hui sur son projet`,
                            `s'impose de lui même`,
                        ]}
                        className={styles.centerQuoteTitle}
                    />
                </Container>
            </section>
            <section className={styles.section}>
                <h2 className={styles.sectionTitle}>
                    La difficulté qu'<strong>on rencontre tous</strong>
                    <br />
                    avec Git
                </h2>
                <Container noTopBottomPadding>
                    <FeatureRow.Component
                        imagePosition={FeatureRow.ImagePosition.IMAGE_RIGHT}
                        title={['Au début', 'tout est simple']}
                        illustrationJSX={
                            <SvgComponent name="newbeeThinkExpert" />
                        }
                    >
                        <p>
                            Tous les développeurs qui ont déjà utilisé git te le
                            diront:{' '}
                            <span className={styles.underline}>
                                au début tout est très simple
                            </span>{' '}
                            et se passe sans difficulté. Très rapidement tu
                            crées ou clones ton premier dépôt, crées une branche
                            et réalises tes premiers commits. Tu prends très
                            vite confiance et as même assez vite le sentiment de
                            maîtriser l'outil.
                        </p>
                    </FeatureRow.Component>
                </Container>
            </section>
            <section className={cx(styles.section, styles.sectionWhite)}>
                <Container noTopBottomPadding>
                    <FeatureRow.Component
                        imagePosition={FeatureRow.ImagePosition.IMAGE_LEFT}
                        title={[
                            'Puis rapidement git add / commit / pull / push ne sont',
                            'plus suffisants',
                            '😡 🪓🪓',
                        ]}
                        illustrationJSX={
                            <div className={styles.demoContainer}>
                                <CodeWindow
                                    src="https://getknow.s3.eu-west-3.amazonaws.com/gif/git-merge-conflict.gif"
                                    alt="remiser les nouveaux fichiers"
                                    isShadowLeft={true}
                                />
                            </div>
                        }
                    >
                        <p>
                            Puis un jour, et ce jour arrive toujours,{' '}
                            <span className={styles.underline}>
                                rien ne se passe comme prévu
                            </span>
                            : git refuse de mettre à jour le dépôt distant et tu
                            ne comprends rien aux messages d’erreur, tu as
                            besoin d'annuler un commit mais tu ne sais pas
                            comment faire, tu voudrais supprimer un fichier de
                            l'historique mais là aussi tu ne sais pas comment
                            faire.
                        </p>
                    </FeatureRow.Component>
                </Container>
            </section>
            <section className={cx(styles.section)}>
                <Container noTopBottomPadding>
                    <FeatureRow.Component
                        imagePosition={FeatureRow.ImagePosition.IMAGE_RIGHT}
                        title={['Tu ne progresses plus vraiment']}
                        svgName="lowProgress"
                    >
                        <p>
                            <span className={styles.underline}>
                                Git n'est pas aussi facile
                            </span>{' '}
                            qu'on aimerait le croire, ou du moins qu’on aimerait
                            nous le faire croire, et cela demande pas mal
                            d'efforts pour arriver à le maîtriser. La courbe de
                            progression très rapide au début s’arrête
                            subitement. Le nombre de commandes et de
                            combinaisons d’options font qu’on est très vite
                            perdu, puis dépassé. Cela semble sans fin.
                        </p>
                    </FeatureRow.Component>
                </Container>
            </section>
            <section className={cx(styles.section, styles.sectionWhite)}>
                <Container noTopBottomPadding>
                    <FeatureRow.Component
                        imagePosition={FeatureRow.ImagePosition.IMAGE_LEFT}
                        title={[
                            'Tu finis par',
                            'perdre trop de temps',
                            'sur les forums, blogs et screencasts',
                        ]}
                        svgName="lostTime"
                    >
                        <p>
                            Tu te tournes alors vers la documentation
                            officielle, très complète, mais aussi extrêmement
                            longue et compliquée. C'est une source d'information
                            extraordinaire principalement dédiée à ceux qui sont
                            déjà expert git.
                        </p>
                        <p>
                            Comme tout le monde tu t'orientes vers des{' '}
                            <span className={styles.underline}>blogs</span>,{' '}
                            <span className={styles.underline}>articles</span>,{' '}
                            <span className={styles.underline}>
                                screencasts
                            </span>
                            ,{' '}
                            <span className={styles.underline}>
                                cheatsheets
                            </span>{' '}
                            et autres contenus gratuits sur le web. A chaque
                            fois tu as l'impression d'apprendre quelque chose de
                            nouveau, une nouvelle astuce. Et pourtant le constat
                            est là: tu as passé des heures et des heures à
                            glaner quelques infos qui n’ont aucun lien entre
                            elles, tu as lu des centaines de fois les mêmes
                            explications de 200 auteurs différents, et malgré
                            tout ça{' '}
                            <span className={styles.underline}>
                                ta progression reste assez lente
                            </span>
                            .
                        </p>
                    </FeatureRow.Component>
                </Container>
            </section>
            <section className={cx(styles.section, styles.quoteWhyCapsules)}>
                <Container>
                    <Quote
                        title={[
                            `Pour aller plus loin`,
                            `tu as besoin d'avoir la bonne approche`,
                            `et les bons outils`,
                        ]}
                        className={styles.quoteWhyCapsulesText}
                    />
                </Container>
            </section>
            <section className={cx(styles.section, styles.commandSection)}>
                <Container>
                    <h2 className={styles.commandSectionTitle}>
                        On a bossé <strong>pour toi</strong>
                    </h2>
                    <p className={styles.commandSectionDescription}>
                        Chaque commande est étudiée en détail au travers des
                        capsules. La commande "git log" à elle seule fait
                        l'objet de 5 capsules. Il s'agit d'une des commandes git
                        les plus complètes et ses secrets sont trop souvent
                        ignorés.
                    </p>
                    <div className={styles.videoContainer}>
                        <CustomVideoPlayer
                            vimeoVideoId="480236891"
                            title="Liste des commandes git étudiées dans cette formation"
                        />
                    </div>
                    <p className={styles.commandSectionSubtitle}>
                        Avec tout ça il y a de quoi faire{' '}
                        <span role="img" aria-label="visage avec monocle">
                            🧐
                        </span>
                    </p>
                    <CommandTable capsules={apiCapsulesGit} />
                </Container>
            </section>
            <section className={cx(styles.sectionWhite)}>
                <SvgComponent name="sectionSuperHeroOrange" />
            </section>

            <section className={cx(styles.section, styles.sectionWhite)}>
                <Container
                    noTopBottomPadding
                    className={styles.knowledgeContainer}
                    size="MD"
                >
                    <h2 className={styles.knowledgeTitle}>
                        <span>Développe tes</span>
                        <span className={styles.knowledgeTitleYellow}>
                            super
                        </span>
                        <span className={styles.knowledgeTitleYellow}>
                            pouvoirs
                        </span>
                    </h2>
                    <p>
                        Comprendre Git, c'est comme un super pouvoir. Cela
                        demande clairement du travail, mais le jeu en vaut la
                        chandelle ! Nos capsules ont été spécialement conçues
                        pour répondre à un maximum de cas d'usage et te donner
                        confiance et sérénité lorsque tu utilises git.
                    </p>

                    <div className={styles.knowledgeList}>
                        {[
                            'Te synchroniser avec un projet git distant',
                            'Maîtriser et réécrire ton historique de commits',
                            'Collaborer sur des projets complexes avec ton équipe',
                            'Modifier, annuler des commits',
                            'Fusionner ou éclater des commits',
                            'Annuler une fusion ou un rebase',
                            'Désindexer sans stress tes fichiers',
                            'Créer, manipuler, synchroniser, fusionner et réecrire des branches',
                            'Retrouver les commits que tu pensais perdus',
                            "Rechercher et filtrer dans l'historique",
                            'Remiser ton travail',
                            'Customiser ton affichage',
                            'Analyser l’historique, visualiser les écarts, retrouver l’origine d’un bug et son auteur',
                            'Comprendre les retours console de git',
                            'Configurer git correctement',
                        ].map(title => {
                            return (
                                <CheckMiniCard
                                    key={title}
                                    forceColor="forceYellow"
                                    title={title}
                                />
                            );
                        })}
                    </div>
                </Container>
            </section>

            <section
                className={cx(styles.section, styles.sampleCapsuleSection)}
            >
                <Container size="MD">
                    <h2 className={styles.sampleSectionTitle}>
                        A quoi ressemble <strong>une capsule</strong> ?
                    </h2>
                    <p>
                        Une capsule de formation c'est du texte, des schémas,
                        des démos, des exemples. C'est simple et efficace !
                    </p>
                    <div className={styles.videoContainer}>
                        <CustomVideoPlayer
                            vimeoVideoId="480237213"
                            title="Utilise les capsules pour te former sur git"
                        />
                    </div>

                    <h2 className={styles.sampleSectionTitle}>
                        A quoi ressemblent <strong>les tests</strong> ?
                    </h2>
                    <p>
                        A chaque capsule correspond une question permettant de
                        valider tes nouveaux acquis{' '}
                        <span role="img" aria-label="bras musclé">
                            💪
                        </span>
                        <br />
                        Nos tests sont composés de 20 questions, tu peux en
                        faire autant que tu veux.
                    </p>
                    <div className={styles.videoContainer}>
                        <CustomVideoPlayer
                            vimeoVideoId="480237307"
                            title="Teste tes nouveaux acquis"
                        />
                    </div>
                    <h2 className={styles.sampleSectionTitle}>
                        A quoi ressemble la section <strong>analyse</strong>
                        &nbsp;?
                    </h2>
                    <p>
                        Démarre de nouveaux tests, retrouve des indicateurs sur
                        ton évolution et ta performance.
                        <br />
                        Retrouve facilement les capsules maîtrisées et celles
                        qui faudrait rebosser.
                    </p>
                    <div className={styles.videoContainer}>
                        <CustomVideoPlayer
                            vimeoVideoId="480236659"
                            title="Analyse tes résultats suite aux tests"
                        />
                    </div>
                </Container>
            </section>

            <section className={cx(styles.section, styles.sectionProgram)}>
                <Container>
                    <header>
                        <h2>
                            <span className={styles.programTitle}>
                                Au programme
                            </span>
                            <span className={styles.programTitleFigure}>
                                {apiCapsulesGit.topics.reduce((prev, next) => {
                                    prev += next.lessons.length;
                                    return prev;
                                }, 0)}
                            </span>
                            <span className={styles.programTitle}>
                                capsules
                            </span>
                        </h2>
                    </header>
                    <CapsulesList
                        topics={apiCapsulesGit.topics}
                        offers={apiOffersGit}
                    />
                </Container>
            </section>
            <section
                id="offers"
                className={cx(styles.section, styles.sectionWhite)}
            >
                <Container noTopBottomPadding className={styles.offerContainer}>
                    <h2
                        className={cx(
                            styles.offerSectionTitle,
                            styles.textOrange
                        )}
                    >
                        Offre de lancement
                    </h2>
                    <div className={styles.promotionBlock}>
                        <p className={styles.promotionTitle}>
                            Profites-en dès maintenant
                        </p>
                    </div>

                    <OfferCards
                        className={styles.offerCards}
                        offers={apiOffersGit}
                    />
                    <div>
                        <SvgComponent
                            name="securePayment"
                            className={styles.securePaymentIcon}
                        ></SvgComponent>
                        <p>
                            Paiement sécurisé par notre prestaire de paiement{' '}
                            <a
                                href="https://stripe.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Stripe
                            </a>
                        </p>
                    </div>
                </Container>
            </section>
            <section className={cx(styles.section, styles.faqContainer)}>
                <Container noTopBottomPadding>
                    <h2 className={styles.faqTitle}>Des questions ?</h2>
                    <Accordion />
                </Container>
            </section>
        </div>
    );
};

export default GitSellingPage;

const CommandTable = ({ capsules }) => {
    return (
        <div className={styles.commandTableGrid}>
            {capsules.topics.map(({ title, lessons }) => (
                <div key={title} className={styles.commandTableCell}>
                    <span className={styles.commandTableCellTitle}>
                        {title}
                    </span>
                    <span className={styles.commandTableCellSubtitle}>
                        {lessons.length} capsules
                    </span>
                </div>
            ))}
        </div>
    );
};
