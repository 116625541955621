import React from 'react';
import PropTypes from 'prop-types';
import AccordionHeader from './header/AccordionHeader';
import AccordionContent from './content/AccordionContent';
import styles from './Accordion.module.scss';

const data = [
    {
        id: 1,
        title: 'Comment accède-t-on aux capsules\xa0?',
        content:
            'Toutes les capsules que tu achètes sont ajoutées dans ton espace perso. Si c’est ta première fois, ton espace perso est automatiquement crée avec tes capsules. Dans tous les cas tu reçois un mail de bienvenue avec le lien de ton espace.',
    },
    {
        id: 2,
        title: 'Peut-on télécharger les capsules\xa0?',
        content:
            'Non, mais tu possèdes en revanche un accès illimité à tes capsules dans ton espace perso.',
    },
    {
        id: 3,
        title: 'Les capsules c’est pour la vie\xa0?',
        content:
            'Et oui, elles n’expirent jamais ! Et en plus, tu profites gratuitement des mises à jour qui peuvent être faites.',
    },
    {
        id: 5,
        title: `Comment se passent les mises à jour\xa0?`,
        content: `Lorsqu’une capsule est mise à jour, elle est immédiatement dispo dans ton espace perso, et ce gratuitement. Elle est pas belle la vie !`,
    },
    {
        id: 6,
        title: `Que faire si je ne trouve pas ma réponse\xa0?`,
        content: `Avec ces capsules, tu vas pouvoir résoudre un bon paquet de problèmes et pour la très grande majorité tu ne devrais même plus avoir besoin de chercher ailleurs. Mais il peut arriver que t’es une question vraiment spécifique et qu’il te faille une option bien particulière. Dans ce cas ton meilleur allié reste la documentation officielle, mais grâce aux capsules tu as à présent suffisamment de connaissances et d’assurance pour te plonger dedans sans risque !`,
    },
    {
        id: 7,
        title: `D’autres capsules sont-elles prévues\xa0?`,
        content: `Oui ! Tout un tas d’autres capsules sont prévues et sur plein de sujets. Les prochaines dans la roadmap sont d’ailleurs sur Javascript, React… et ce n’est qu’un début !`,
    },
    {
        id: 8,
        title: `Que faire si je ne suis pas convaincu\xa0?`,
        content: `Tu bénéficies d’une garantie de 14 jours à partir de la date d’achat pour être intégralement remboursé si tu n’es pas convaincu. Envoie nous simplement en email à contact@ledevwebmoderne.fr pour qu’on désactive tes capsules et qu’on procède au remboursement.`,
    },
    // {
    //     id: 9,
    //     title: `Y’a-til de réductions étudiants\xa0?`,
    //     content: `Bien sûr, mais à condition d’être vraiment étudiant ! Fais nous juste suivre une preuve de ta scolarité à contact@ledevwebmoderne.fr et tu recevras par email un code promo à usage unique pour le package de capsules de ton choix.`,
    // },
    {
        id: 10,
        title: `Tu aimerais une capsule qui n’existe pas, ou tu as des idées d’amélioration\xa0?`,
        content: `On est toujours preneur de bonnes idées ! Fais nous suivre tout ça à contact@ledevwebmoderne.fr.`,
    },
    {
        id: 11,
        title: `D’autres questions ou envie de dire bonjour\xa0?`,
        content: `N’hésite pas, envoi nous un email à contact@ledevwebmoderne.fr !`,
    },
];

class Accordion extends React.Component {
    state = this.initState();

    initState() {
        return this.props.data.reduce((prev, next) => {
            prev[next.id] = false;
            return prev;
        }, {});
    }

    handleOnHeaderClick = id => {
        this.setState(prevState => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    render() {
        return (
            <section>
                {this.props.data.map(item => {
                    return (
                        <article key={item.id} className={styles.accordion}>
                            <AccordionHeader
                                title={item.title}
                                isActive={this.state[item.id]}
                                onClick={() =>
                                    this.handleOnHeaderClick(item.id)
                                }
                            />
                            <AccordionContent isVisible={this.state[item.id]}>
                                {item.content}
                            </AccordionContent>
                        </article>
                    );
                })}
            </section>
        );
    }
}

Accordion.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
        })
    ),
};
Accordion.defaultProps = {
    data: data,
};
export default Accordion;
